import axios from 'axios';
import React from 'react'
import {Modal, Row, Col} from 'react-bootstrap';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import InputField from '../../common/input-fields/InputField';
import ToolTipEx from '../../common/ToolTipEx'
import Form from 'react-bootstrap/Form';
import './custom-css.css'
import { showMessage, updateNotification} from '../commonFunctions';
import MultiReviewerList from './MultiReviewerList';
import {DateTime} from "luxon"
import { yyyymmddFormat } from '../../common/dateFormat';
import FileUploader from '../FileUploader';
import {DEFAULT,REVIEWS} from '../../../TooltipInfo'
import { dateformat, dateStringformat, ddmmmyyyyformat } from '../../../utility/helperFunctions';
import SharepointConnect from '../SharepointConnect';
import Button from '../../common/Button';
import moment from 'moment';
import { GLOBAL_DATE_FORMAT } from '../../../utility/dateHelper';
export default class InitiateReview extends React.Component{
    static contextType = UserContext;
    SERVICE_URL={
        ADD_REVIEW:'reviewConcept/review/add',
        UPDATE_REVIEW:'reviewConcept/review/update',
        EXT_URL:'user/externalurl',
        USR_LST:'user/data',
        REVIEW_DETAIL:'reviewConcept/reviewDetail'
    };
    DBErrors={
        DUPLICATE:'SequelizeUniqueConstraintError'
    };
    INITIAL_STATE={
        externalurl:'',
        modalProps:{show:false},
        isIUDOperation:false,
        usersList:[],
        reviewTypes:[
            
        ],
        publicationRecords:[],
        amendmentRecords:[],
        defaultMinDate:new Date(),
        committeeTypes:[],
        reviewerTypes:[],
        approvalOrderTypes:[],
        fileInfo:{
            selectedFileName:'',
            file:null
        },
        sharePointfileInfo:{
            selectedFileName:'',
            file:null
        },
        instructionData:[],
        isMultiReviewerOptionEnabled:false,
        multiReviewerSourceData:[
            // {
            //     reviewer_system_id:'L035986',
            //     reviewer_name:"Rajesh N G-1",
            //     order:1,
            //     approval_order:'Sequential',
            //     is_selected:false,
            //     reviewer_type:'None',
            //     is_committee:false,
            //     due_date:null,
            //     id:1
            // },
            // {
            //     reviewer_system_id:'L035987',
            //     reviewer_name:"Rajesh N G-2",
            //     order:2,
            //     approval_order:'Sequential',
            //     is_selected:false,
            //     reviewer_type:'None',
            //     is_committee:false,
            //     due_date:null,
            //     id:2
            // },
            // {
            //     reviewer_system_id:'L035988',
            //     reviewer_name:"Rajesh N G-3",
            //     order:3,
            //     approval_order:'Sequential',
            //     is_selected:false,
            //     reviewer_type:'None',
            //     is_committee:false,
            //     due_date:null,
            //     id:1
            // },
            // {
            //     reviewer_system_id:'L035989',
            //     reviewer_name:"Rajesh N G-4",
            //     order:4,
            //     approval_order:'Sequential',
            //     is_selected:false,
            //     reviewer_type:'None',
            //     is_committee:false,
            //     due_date:null,
            //     id:2
            // }
           ],
        
        formData:{
            fields:{
                review_type:"",
                review_for:null,
                review_to:"",
                instruction_to:"",
                is_multi_reviewer:true,
                due_date:null,
                review_to_multi:null,
                is_committee:null,
                due_date:null,
                approval_order:null
            },
          

            errors:{
                
            }

        }
    }
    MESSAGES={
        "SEQUENTIAL-DUEDATE":"Please enter valid due date for the selected reviewer",
        "SELECTION-APPROVALORDER":"Please select valid approval order",
        "REVIEWER-MANDAOTRY":"Please select any reviewer"
    }
    constructor(props){
        super(props);
        this.filePicker=React.createRef(null);
        this.state={
            ...this.INITIAL_STATE
        }
    }
    async componentDidMount(){
        this.getUserList();
        this.getReviewTypes();
        await this.getExternalUrl();
        this.getReviewerDetails();
    }
    componentDidUpdate(prevProps, prevState){
        //console.log(this.state);
        //console.log(this.props);
        if (prevState.modalProps.show !== this.props.show) {
            this.setState({modalProps:{show:this.props.show}});
          }
    }
    getReviewerList=async(review_type,reviewerId)=>{
        try{
            
            const res = await API.post(`reviewsTab/reviews/list`,{
                    "review_type": review_type,
                    "review_header_id": reviewerId||''
            });   

            if(res&&res.data){
              return res.data||[];
            }
        }
        catch(exp){

        }
        finally{
          
        }
        return [];
    }
    getReviewerDetails=async()=>{
        try{
            let {id}=this.props;
            if(id){
                this.setLoader(true);
                const res = await API.get(`${this.SERVICE_URL.REVIEW_DETAIL}/${id}`)
                this.setLoader(false);
                if(res&&res.data){
                    let dbResponse=JSON.parse(JSON.stringify(res.data));
                 let {formData,multiReviewerSourceData}=this.state;
                     let {is_multi_reviewer,review_type,reviewers_details,review_for}=res.data;
                     let reviewersList=await this.getReviewerList(review_type,id);
                     formData.fields.review_for=review_for;
                     formData.fields.review_type=review_type;
                     is_multi_reviewer=true;
                     //Single Reviewer Option has been removed and always multi reviewer
                     if(is_multi_reviewer){
                        formData.fields.is_multi_reviewer=is_multi_reviewer;
                     
                        multiReviewerSourceData=reviewers_details;
                        (reviewers_details || []).forEach((item, index) => {
       
                           //approval_order >> order
                           item.order=item.approval_order  || null;
                           //approval_order_type >> approval_order                        
                           item.approval_order= item.approval_order_type||null;
                           //*purging order field from object
                           delete item.approval_order_type;

                          let isProtected=reviewersList.find(p=>p.reviewer_system_id===item.reviewer_system_id&&p.review_data&&p.review_data.length>0);
                            if(isProtected){
                                item.disable_edit=true;
                            }
                            console.log("isProtected",item);
                       });
                     }
                    else{
                       if(reviewers_details&&reviewers_details.length>0){
                        formData.fields.review_to= reviewers_details[0].reviewer_system_id;
                        formData.fields.due_date= dateStringformat(reviewers_details[0].due_date);
                       }
                    }
                    
                     this.setState({formData:{...formData},multiReviewerSourceData,isEditMode:true,dbResponse});
                }
            }
        }
        catch(exp){

        }
        finally{
            this.setLoader(false);
        }        
       
    }
  
    getAdditionalForms=async()=>{
        try{
            let sc=new SharepointConnect();
            let stateInfo={};
            await sc.loadConfigInformation().then((data)=>{    
                stateInfo=data;
            });
           let data= await sc.getLists();
           return data||[];
        }
        catch(exp){

        }
       return [];
      // await sc.getFields("7efcbab8-2ddc-45c6-8b9b-66254750ce6f");
    }
    clearFormData=(callBack,isSuccess)=>{
        this.setState({
            ...this.INITIAL_STATE
        },()=>{callBack(isSuccess);});
    }
    getReviewTypes=async()=>{
       
        let types=["Concept","Proposal","Protocol","Publication","Amendment"];
        let res= await API.get(`reviewsTab/reviewType/list/${this.props.submissionId}`); 
        let additionForms=await this.getAdditionalForms();
        let committeType=["Yes","No"];
        let reviewerTypes=["Committee","Financials","IIR Coordinator","Legal","Medical Reviewer","Other","Patient Safety","Product Delivery","Stats"];
        let approvalOrder=["Sequential","Parallel"];
        const resultArray = types.map(elm => ({

            name: elm,
            value: elm,
            disabled:res.data.data[elm.toLowerCase()].length== 0
           
        }));
        //console.log("resultarray",resultArray);

        
        const committeeResultArray = committeType.map(elm => ({
            name: elm,
            value: elm
        }));
        const medicalResultArray = reviewerTypes.map(elm => ({
            name: elm,
            value: elm
        }));
        const approvalResultArray = approvalOrder.map(elm => ({
            name: elm,
            value: elm
        }));
        const additionFormsArray = additionForms.map(elm => ({
            name: elm["Title"],
            value: elm["Id"]
        }));
        this.setState({ 
            reviewTypes: resultArray,
            milestoneData:res.data?.data || [],
            committeeTypes:committeeResultArray,
            reviewerTypes:medicalResultArray,
            approvalOrderTypes:approvalResultArray,
            additionalForms:additionFormsArray||[]
        });
        console.log('milestone-->',this.state.milestoneData);
        this.getAmendmentPublicationRecords();
    }
    getAmendmentPublicationRecords = () => {
        let publicationRecords = this.state.milestoneData.publication;

        publicationRecords = publicationRecords.map(elm => ({
            value: elm.id,
            name: [elm.format || '', elm.venue || '', elm.created_at?moment.utc(elm.created_at, false).local().format(GLOBAL_DATE_FORMAT):''].filter(p => p.length > 0).join(", ")
        }));

        let amendmentRecords = this.state.milestoneData.amendment;
        let index=0;
        amendmentRecords = amendmentRecords.map(elm => ({
            value: elm.id,
            name: elm.id
        }));
        this.setState({
            publicationRecords: publicationRecords,
            amendmentRecords: amendmentRecords
        });
    }

    getUserList = async () => {
        const res = await API.get(this.SERVICE_URL.USR_LST)
        const resultArray = res.data.map(elm => ({
            name: `${elm.first_name} ${elm.last_name} | ${elm.system_id}`,
            value: elm.system_id,
            fullName:`${elm.first_name} ${elm.last_name}`,
        }));
        this.setState({ usersList: resultArray });
    }

    onHide=()=>{
        let {onClose}=this.props;
        if(onClose){
            
            this.clearFormData(onClose);
            
        }
    }
    handleChangeEvent=(e,name)=>{
        // console.log(e)
        // console.log('name',name);
      
       let {formData}=this.state;
        let fieldName=name;
        if(!name){
            const { name, value} = e.target;
            fieldName=name;
            formData.fields[name]=value;
            if(name=="is_multi_reviewer"){
                formData.fields[name]=e.target.checked;
                formData.fields["review_to"]=null;
            }
           
        }
        else{
            formData.fields[name]=e.value;
            if(name==="addtnl_review_form"){
                formData.fields["addtnl_review_form_name"]=e.name;
            }
        }
        if(name=='review_type'){
            formData.fields['review_for']=null;
        }
        this.setState({formData:{...formData}},()=>{
            if(fieldName==="instruction_to"&& formData.fields["is_multi_reviewer"]===true){
                this.setInstructionData();
            }  
            if(fieldName==="addtnl_review_form"&& formData.fields["is_multi_reviewer"]===true){
                this.setAdditionalFormData();
            }           
        });
    }
    validateInputs=()=>{
        let {formData}=this.state;
        let {errors}=formData;
        errors={};
        let isValid=true;       
        if(!formData.fields["review_to"]&&!formData.fields["is_multi_reviewer"]){
            errors.review_to=true;
            isValid=false;
        }
        if(!formData.fields["review_type"]){
            errors.review_type=true;
            isValid=false;
        }
        if(["AMENDMENT","PUBLICATION"].includes((formData.fields["review_type"]||"").toUpperCase())&&!formData.fields["review_for"]){
            errors.review_for=true;
            isValid=false;
        }
        if(!formData.fields["is_multi_reviewer"]&&!formData.fields["due_date"]){
            errors.due_date=true;
            isValid=false;
        }
        formData.errors=errors;
        this.setState({formData});
        return isValid;
    }
    validateMultiReviewerInputs=(data)=>{
        let {reviewers_details}=data;
        if(!reviewers_details||reviewers_details.length<=0){
            showMessage({                  
                text:this.MESSAGES['REVIEWER-MANDAOTRY'],
                icon: "error",
                button: "Ok",              
              },(e)=>{
                
              });
            return false;
        }
        else{
           let inValidDates= reviewers_details.filter(p=>!p.due_date&&p.approval_order_type==="Sequential");
           if(inValidDates.length>0){
            showMessage({                  
                text:this.MESSAGES['SEQUENTIAL-DUEDATE'],
                icon: "error",
                button: "Ok",              
              },(e)=>{

              });
            return false;
           }
        }
        return true;
    }
    setLoader=(show)=>{
        this.setState({isIUDOperation:show});
    }
    generateMultiiReviewerInput=()=>{
        let { multiReviewerSourceData, formData,dbResponse,isEditMode } = this.state;
        let freeDataEx = multiReviewerSourceData.map((item,index)=>{
            let field={...item};
            if(field.due_date){
                field.due_date=yyyymmddFormat(item["due_date"]);
            }
            return field;
        });        
        let freeData = JSON.parse(JSON.stringify(freeDataEx));
        let instructions = [];       
        (freeData || []).forEach((item, index) => {
            
            //approval_order_type << approval_order
            item.approval_order_type = item.approval_order || null;
            //approval_order << order
            item.approval_order = item.order || null;
            
            //*purging order field from object
            delete item.order;
            //pushing instruction data
            if(item.instruction_to&&item.instruction_to.length>0){
                instructions.push({ system_ids: [item.reviewer_system_id], instruction: item.instruction_to });
            }
           
            //*purging instruction_to field from object
            delete item.instruction_to;
        });
        if(dbResponse&&isEditMode){
            let deletedItems=[];
            dbResponse.reviewers_details.forEach((item,index)=>{
                if(!freeData.find(p=>p.id===item.id)){
                    item.is_deleted=true;
                    deletedItems.push(item);
                }
            });
            if(deletedItems.length>0){
                freeData=[...freeData,...deletedItems];
            }
        }
       
        let formBody = {
            "submission_id": this.props.submissionId,
            "review_type": formData.fields["review_type"],
            "created_by": this.context.userSystemId,
            "updated_by": this.context.userSystemId,
            "is_multi_reviewer": formData.fields["is_multi_reviewer"],
            "reviewers_details": freeData,
            "instructions": instructions,
            "review_for": formData.fields["review_for"]
        };
        if(dbResponse&&isEditMode){
            formBody.id=dbResponse.id;
        }
        return formBody;
    
    }
    generateSingelReviewerInput=()=>{
        let {  formData,dbResponse,isEditMode } = this.state;       
       
        let formBody = {
            "submission_id": this.props.submissionId,
            "review_type": formData.fields["review_type"],
           
            "created_by": this.context.userSystemId,
            "updated_by": this.context.userSystemId,
            "is_multi_reviewer": false,
            "reviewers_details": [{
                reviewer_system_id:formData.fields["review_to"],
                "due_date":yyyymmddFormat(formData.fields["due_date"]),
            }],
            "instructions": [{ system_ids: [formData.fields["review_to"]], instruction: formData.fields["instruction_to"] }]
        };
        if(!formData.fields["instruction_to"]||formData.fields["instruction_to"].length<=0){
            formBody["instructions"]=[];
        }
        if(dbResponse&&isEditMode){
            formBody.id=dbResponse.id;
            if(!dbResponse.is_multi_reviewer){
                let isFOund=dbResponse.reviewers_details.find(p=>p["reviewer_system_id"]===formData.fields["review_to"]);
                if(isFOund){
                    formBody.reviewers_details[0].id=isFOund.id;
                }
            }           
            let deletedItems=[];           
            dbResponse.reviewers_details.forEach((item,index)=>{
                if(!formBody.reviewers_details.find(p=>p.id===item.id)){
                    item.is_deleted=true;
                    deletedItems.push(item);
                }                
            });
            if(deletedItems.length>0){
                formBody.reviewers_details=[...formBody.reviewers_details,...deletedItems];
            }
        }
       
        return formBody;
      
    }
    handleSave=async ()=>{
        //debugger;
        this.setLoader(true);
        let {formData,isEditMode}=this.state;
        let formBody={};
        let continue_=true;
        if(this.validateInputs()){          
        
            if (formData.fields.is_multi_reviewer) {               
                formBody = this.generateMultiiReviewerInput();
                continue_=this.validateMultiReviewerInputs(formBody);
            }
            else {
                formBody = this.generateSingelReviewerInput();
            }
            if (continue_) {
                let api_path=isEditMode?this.SERVICE_URL.UPDATE_REVIEW:this.SERVICE_URL.ADD_REVIEW;
                const res = await API.post(api_path, formBody);
                if (!res.data.success) {
                    this.setLoader(false);
                    let isDuplicate = (res.data.data.name === this.DBErrors.DUPLICATE);
                    if (isDuplicate) {
                        showMessage({
                            text: "Record already exist",
                            icon: "warning",
                            button: "Ok",
                        }, (e) => { });
                    }
                    else {
                        showMessage({
                            text: "Failed to update the record!",
                            icon: "error",
                            button: "Ok",

                        }, (e) => { this.clearFormData(this.props.onClose, false) });
                    }
                }
                else {
                    //alert("saved successfully");
                    //console.log(res.data);
                    let uploadFile = false;
                    if (formData.fields.is_multi_reviewer) {
                        uploadFile = await this.initiateFileUploadEx(res.data.data);
                    }
                    else {
                        uploadFile = await this.initiateFileUpload(res.data.data);
                    }
                    //debugger;
                    this.clearFormData(this.props.onClose, true);
                }
            }
                
                }
            
            this.setLoader(false);
    }
     getExternalUrl=async ()=> {
        //debugger;
        let response ='';
        try {
             response = await API.get(this.SERVICE_URL.EXT_URL);
             //console.warn(response);
        }
        catch (err) {
            //console.log(err);
        }
        this.setState({
        externalurl : (response && response.data) ? response.data.exturl : "",
    })
    }

    initiateSharepointUpload=async(data)=>{
      
        let {multiReviewerSourceData,formData}=this.state;
        let review_type=formData.fields["review_type"];
        if(data){
            let { id,reviewers_details  }=data;
            let fileData=[];
            multiReviewerSourceData.forEach((item,index)=>{
                if(item.sharePointfileInfo){
                    let current=reviewers_details.find(p=>p.reviewer_system_id===item.reviewer_system_id);
                    fileData.push({
                        fileInfo:item.sharePointfileInfo,                       
                        submissionId:this.props.submissionId,
                        review_type:review_type,
                        review_header_id:id,
                        reviewers_detail_id:current.id
                    });
                }               
            });
           
            if (fileData && fileData.length > 0) {
                let asyncFiles = [];
                let sc=new SharepointConnect();
                let stateInfo={};
                await sc.loadConfigInformation().then((data)=>{    
                    stateInfo=data;
                });

                fileData.forEach((item, index) => {
                   let newState=Object.assign(stateInfo,item);
                   let sc_uploader=new SharepointConnect(newState);
                   let response= sc_uploader.uploadFile(item.fileInfo.file);
                   asyncFiles.push(response);
                });
                let records = [];
                await Promise.all(asyncFiles)
                .then((d) => {
                    records=[...d];
                });
            }
        }
       
    }
    initiateFileUploadEx=async(data)=>{
        //fileInfo
        let pass=true;
        await this.initiateSharepointUpload(data);
        let {multiReviewerSourceData}=this.state;
        if(data){
            let { id,reviewers_details  }=data;
           //let freeData = JSON.parse(JSON.stringify(multiReviewerSourceData));
            let fileData=[];
            multiReviewerSourceData.forEach((item,index)=>{
                if(item.fileInfo){
                    let current=reviewers_details.find(p=>p.reviewer_system_id===item.reviewer_system_id);
                    fileData.push({
                        fileInfo:item.fileInfo,
                        reviewers_detail_id:current.id,
                        review_header_id:id,
                        document_custom_name:null
                    });
                }               
            });
            //debugger;
            if (fileData && fileData.length > 0) {
                let { externalurl } = this.state;
                let asyncFiles = [];
                fileData.forEach((item, index) => {
                    if (item.fileInfo && item.fileInfo.file) {
                        let formData = new FormData();
                        formData.append(        
                            'file',
                            item.fileInfo.file,
                            item.fileInfo.selectedFileName
                        );
                        formData.append(
                            'created_by',
                            this.context.userSystemId
                        );
                        formData.append(
                            'updated_by',
                            this.context.userSystemId
                        );
                        formData.append(
                            'reviewers_detail_id',
                            item.reviewers_detail_id
                        );
                        formData.append(
                            'review_header_id',
                            item.review_header_id
                        );
                        
                        asyncFiles.push(formData);
                    }

                });
                let i = 0;
                //debugger;
                if(externalurl){

                    let dataCollection = [];
                do {
                    let fileUrl = `${externalurl}/fileupload/Reviewer/${this.props.submissionId}`;
                    let res = API.post(fileUrl, asyncFiles[i]);
                    dataCollection.push(res);
                    i++;
                }
                while (i < fileData.length);
                let records = [];
                //debugger;
                await Promise.all(dataCollection)
                    .then((dataOut) => {
                        records = [];
                        dataOut.map((res) => {
                            if(res.data){
                                records = [...records, ...res.data];
                            }
                          
                        })
                        //debugger;
                        return records;
                    })
                    .catch((e) => {
                        //console.log(e)
                       
                        records = [];
                    });
            }
        }
        }
        if(pass&&data&&data.id){
            //item.sharePointfileInfo
            //debugger;
            if(multiReviewerSourceData&&multiReviewerSourceData.length>0){
                let hasFiles=multiReviewerSourceData.filter(p=>p.sharePointfileInfo||p.fileInfo);
                if(hasFiles.length>0){
                    let systemids=hasFiles.map(p=>p.reviewer_system_id);
                    let params=systemids.join(",");
                    updateNotification(this.context.userSystemId,data.id,params);
                }
            }
          
        }
    }
    initiateFileUpload=async (data)=>{
       
        const formData = new FormData();
        let {fileInfo}=this.state;
        if (fileInfo && fileInfo.file && this.state.externalurl) {
            let { id,reviewers_details  }=data;
            formData.append(
                'file',
                fileInfo.file,
                fileInfo.selectedFileName
            );
            formData.append(
                'created_by',
                this.context.userSystemId
            );
            formData.append(
                'updated_by',
                this.context.userSystemId
            );
            formData.append(
                'reviewers_detail_id',
                reviewers_details[0].id
            );
            formData.append(
                'review_header_id',
                id
            );
            
            try {
                let fileUrl = `${this.state.externalurl}/fileupload/Reviewer/${this.props.submissionId}`;
                let res = await axios.post(fileUrl, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    },
                });
                // alert("finished")
                // console.info(res);
                return true;

            } catch (e) {
                // alert("failed")
                // console.info(e);
                return false;
            }
        }
        return true;
    }
    getEmptyMultiReviewerSettings=()=>{
        let {formData}=this.state;
        formData.fields.review_to_multi=null;
        formData.fields.is_committee=null;
        formData.fields.due_date=null;
        formData.fields.approval_order=null;
        formData.fields.reviewer_type=null;
        return  formData;
    }
    handleOnSelection=(data,mode)=>{
        let formData=this.getEmptyMultiReviewerSettings();
        this.setState({multiReviewerSourceData:data,formData:{...formData}},()=>{
            if(mode==="SELECTION"){
                this.loadInstructionData();
                this.loadFileInfoData();    
                this.setDefaultMinDateForSequential();          
            }
        });
        
    }
    clearMultiReviewerForms=()=>{
        let {formData}=this.state;
        ["review_to_multi","is_committee","due_date","approval_order","reviewer_type"].forEach((item)=>{
            formData.fields[item]=null;
        });
        
        this.setState({formData:{...formData}});
    }
    isValidApprovalOrderChange=(data)=>{
        if(data==="Sequential"&&!this.isValidReviewerSelection()){
            showMessage({                  
                text: this.MESSAGES['SELECTION-APPROVALORDER'],
                icon: "warning",
                button: "Ok",
              
              },(e)=>{

              });
              return false;
        }
        else if(data==="Sequential"&&this.isSequentialSelection()){
            return false;
        }
        return true;
    }
    onDateChange = (e,eo) => {
        let {formData}=this.state;
        formData.fields[eo]=e;
        //console.log(e);
        if(!formData.fields["is_multi_reviewer"]){
            this.setState({formData:{...formData}});
        }
        else if(this.isValidReviewerSelection()){
           if(this.isValidDate(e)){
            let multiReviewerSourceData= this.triggerMultiReviewerDataChange(eo,formData);
            this.setState({formData:{...formData},multiReviewerSourceData:[...multiReviewerSourceData]}); 
           }  
           else{
           
            showMessage({                  
                text: this.MESSAGES['SEQUENTIAL-DUEDATE'],
                icon: "warning",
                button: "Ok",
              
              },(e)=>{

              });
           }          
        }
        
    }
    isValidDate=(selectedDate)=>{
        //debugger;
        let {multiReviewerSourceData}=this.state;
        let unSelectedValues=multiReviewerSourceData.filter(p=>!p.is_selected);
        let currentSelection=multiReviewerSourceData.find(p=>p.is_selected);
        if(unSelectedValues.length>0 && currentSelection.approval_order==="Sequential"){
            let checkFutureDates=unSelectedValues.filter(p=>p.due_date&&p.due_date<selectedDate&&p.approval_order=="Sequential"&&p.order>currentSelection.order);
            if(checkFutureDates.length>0) return false;
            let checkPrevDates=unSelectedValues.filter(p=>p.due_date&&p.due_date>selectedDate&&p.approval_order=="Sequential"&&p.order<currentSelection.order);
            if(checkPrevDates.length>0) return false;            
        }
        return true;
    }
    handleChangeEventEx=(e,name)=>{      
       
       let {formData}=this.state;
        if(!name){
            const { name, value} = e.target;
           // console.log(value)
            formData.fields[name]=value;
            if(name=="is_multi_reviewer"){
                formData.fields[name]=e.target.checked;
            }
        }
        else{
            formData.fields[name]=e.value;
        }
        let isValid=true;
        if(name=="approval_order"){
            isValid=this.isValidApprovalOrderChange(formData.fields["approval_order"]);
        }
        if(isValid){
            let multiReviewerSourceData= this.triggerMultiReviewerDataChange(name,formData);       
            this.setState({formData:{...formData},multiReviewerSourceData:[...multiReviewerSourceData]},()=>{
                if(name=="review_to_multi"){
                 
                this.clearMultiReviewerForms();
                }
            });
        }
       
    }
    loadAdditionalFormData=()=>{
        let {multiReviewerSourceData,formData}=this.state;
        let selectedItems=multiReviewerSourceData.filter(p=>p.is_selected);
        let instructions=[];
        formData.fields["addtnl_review_form"]="";
        if(selectedItems.length>0){           
             let isAllEqual= selectedItems.every( (val, i, arr) => val.addtnl_review_form === arr[0].addtnl_review_form );
            
             if(isAllEqual){
                formData.fields["addtnl_review_form"]=selectedItems[0].addtnl_review_form||"";
             } 
        }
        this.setState({formData:{...formData}});
    }
    loadInstructionData=()=>{
        let {multiReviewerSourceData,formData}=this.state;
        let selectedItems=multiReviewerSourceData.filter(p=>p.is_selected);
        let instructions=[];
        formData.fields["instruction_to"]="";
        if(selectedItems.length>0){           
             let isAllEqual= selectedItems.every( (val, i, arr) => val.instruction_to === arr[0].instruction_to );
            
             if(isAllEqual){
                formData.fields["instruction_to"]=selectedItems[0].instruction_to||"";
             } 
        }
        this.setState({formData:{...formData}},this.loadAdditionalFormData);
    }
    setDefaultMinDateForSequential=()=>{
        
        let {multiReviewerSourceData,fileInfo}=this.state;
        let selectedItems=multiReviewerSourceData.filter(p=>p.is_selected);
        let dfDate=new Date();
        if(selectedItems.length===1){
            let order=selectedItems[0].order;
            let isAnyPrevOrder=multiReviewerSourceData.filter(p=>p.due_date&&p.approval_order==="Sequential"&&p.order<order);
            if(isAnyPrevOrder.length>0){
                isAnyPrevOrder.sort((a, b)=> {
                    return a.order - b.order;
                 });
                 let item=isAnyPrevOrder.pop();
                if(item){  
                    if(typeof item.due_date=== 'string'){
                        item.due_date=new Date(item.due_date);
                    }
                    dfDate=item.due_date||new Date(); 
                    if(item.due_date<new Date(yyyymmddFormat(new Date()))){
                        dfDate=new Date();    
                    }
                                
                }
            }            
        }
        this.setState({defaultMinDate:dfDate});
    }
    loadFileInfoData=()=>{
        let {multiReviewerSourceData,fileInfo,sharePointfileInfo}=this.state;
        let selectedItems=multiReviewerSourceData.filter(p=>p.is_selected);
        let instructions=[];
        fileInfo={
            selectedFileName:'',
            file:null
        };
        sharePointfileInfo={
            selectedFileName:'',
            file:null
        };
        if(selectedItems.length>0){           
             let isAllEqual= selectedItems.every( (val, i, arr) => val.fileInfo === arr[0].fileInfo );
            
             if(isAllEqual){
                fileInfo=selectedItems[0].fileInfo||{
                    selectedFileName:'',
                    file:null
                };
             } 
             isAllEqual= selectedItems.every( (val, i, arr) => val.sharePointfileInfo === arr[0].sharePointfileInfo );
             if(isAllEqual){
                sharePointfileInfo=selectedItems[0].sharePointfileInfo||{
                    selectedFileName:'',
                    file:null
                };
             } 
        }
        this.setState({fileInfo:{...fileInfo},sharePointfileInfo:{...sharePointfileInfo}});
    }
    setFilesData=(isSharepointFile)=>{
        let {multiReviewerSourceData,fileInfo,sharePointfileInfo}=this.state;
        let selectedItems=multiReviewerSourceData.filter(p=>p.is_selected);
        if(selectedItems.length>0){  
            for (var value of selectedItems) {
                if(isSharepointFile){
                    value.sharePointfileInfo=sharePointfileInfo;
                }
                else{
                    value.fileInfo=fileInfo;
                }
               
              }
        }
        this.setState({multiReviewerSourceData:[...multiReviewerSourceData]});
    }
    setInstructionData=()=>{
        let {multiReviewerSourceData,formData}=this.state;
        let selectedItems=multiReviewerSourceData.filter(p=>p.is_selected);
        if(selectedItems.length>0){  
            for (var value of selectedItems) {
               value.instruction_to=formData.fields["instruction_to"];
              }
        }
        this.setState({multiReviewerSourceData:[...multiReviewerSourceData]});
    }
    setAdditionalFormData=()=>{
        let {multiReviewerSourceData,formData}=this.state;
        let selectedItems=multiReviewerSourceData.filter(p=>p.is_selected);
        if(selectedItems.length>0){  
            for (var value of selectedItems) {
               value.addtnl_review_form=formData.fields["addtnl_review_form"];
               value.addtnl_review_form_name=formData.fields["addtnl_review_form_name"];
              }
        }
        this.setState({multiReviewerSourceData:[...multiReviewerSourceData]});
    }
    newReviewerTemplate=(data)=>{
       let {value,fullName}=data;
       let {multiReviewerSourceData}=this.state;
       let maxSequential=Math.max(...multiReviewerSourceData.map(o => o.order||0), 0);
        return {
            reviewer_system_id:value,
            reviewer_name:fullName,
            order:maxSequential+1,
            approval_order:"Sequential",
            is_selected:false,
            reviewer_type:null,
            is_committee:false,
            due_date:null,
            addtnl_review_form:null,
            addtnl_review_form_name:null
        }
    }
    triggerMultiReviewerDataChange=(field,formData)=>{
        let {multiReviewerSourceData}=this.state;
        let updateValue= formData.fields[field];
       
        if(field!=="review_to_multi"){
            let selectedReviewer=multiReviewerSourceData.filter(p=>p.is_selected);
            if(selectedReviewer.length>0){
                selectedReviewer.map((item,index)=>{
                    item[field]=updateValue;
                    if(field==="approval_order"){
                        
                        item["order"]=null;
                    }
                });
                //this.setState({multiReviewerSourceData:[...multiReviewerSourceData]});
            }
        }
        else{
          
                let {usersList}=this.state;
                let userData=usersList.find(p=>p.value===updateValue);
                let isExist=multiReviewerSourceData.find(p=>p.reviewer_system_id===userData.value);
                if(userData&&!isExist){
                    let reviewerData=this.newReviewerTemplate(userData);
                    multiReviewerSourceData= [...multiReviewerSourceData,reviewerData];
                    multiReviewerSourceData.forEach((item,index)=>{
                        item.is_selected=false;
                    })
                }
        }
        //console.log(multiReviewerSourceData)
        return multiReviewerSourceData;
    }
    isValidReviewerSelection=()=>{
        let {multiReviewerSourceData}=this.state;
        let selectedItems=multiReviewerSourceData.filter(p=>p.is_selected);
        return selectedItems.length===1;
    }
    isSequentialSelection=()=>{
        let {multiReviewerSourceData}=this.state;
        let selectedItems=multiReviewerSourceData.filter(p=>p.is_selected);
        return selectedItems.length===1&&selectedItems[0].approval_order==="Sequential";
    }
    isReviewerIsSelected=()=>{
        let {multiReviewerSourceData}=this.state;
        let selectedItems=multiReviewerSourceData.filter(p=>p.is_selected);
        return selectedItems.length>0;
    }
   
    render(){
        let {modalProps,formData,fileInfo,isIUDOperation,isEditMode,sharePointfileInfo}=this.state;
        let loaderConfig={};
        if(isIUDOperation){
             loaderConfig={disabled:"disabled"};
        }
        let isReviewerSelected=this.isReviewerIsSelected();
        return <div>
    <Modal
      {...modalProps}
     
      size="lg"
      className="modal-mdx"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

    <Modal.Header style={{border:0}}>
        <Modal.Title id="contained-modal-title-vcenter">
         <h5 className="font-weight-bold"> Initiate New Review</h5>
         <div style={{fontSize:'1rem'}}>
            <span>Please fill out this form to create a new review.</span>
         </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="new-flow-modal">
            <Row>
                <Col sm={12}>
                    <InputField
                           label= "Review Type *"
                           type= "searchableDropdown"
                           name= "review_type"
                           value={formData.fields["review_type"]}
                           error={formData.errors["review_type"]}
                           placeholder="Select"
                           onSelect={(e,name)=>this.handleChangeEvent(e,name)}
                           options={this.state.reviewTypes}
                           disabled={isEditMode}
 
                        />
                </Col>
                {(formData.fields["review_type"]==='Publication' || formData.fields["review_type"]==='Amendment') &&
                <Col sm={12}>
                    <InputField
                           label= "Document *"
                           type= "searchableDropdown"
                           name= "review_for"
                           value={formData.fields["review_for"]}
                           error={formData.errors["review_for"]}
                           placeholder="Select Document"
                           onSelect={(e,name)=>this.handleChangeEvent(e,name)}
                           {...(formData.fields["review_type"]==='Publication' &&
                            {options:this.state.publicationRecords})}
                           {...(formData.fields["review_type"]==='Amendment' &&
                            {options:this.state.amendmentRecords})}
                           disabled={isEditMode}
                        />
                </Col>}
                
                {/* <Col sm={12}>
                    <InputField
                           label= "Assign Review To *"
                           type= "searchableDropdown"
                           placeholder="Select Reviewer"
                           error={formData.errors["review_to"]}
                           name= "review_to"
                           value={formData.fields["review_to"]}
                           options= {this.state.usersList}
                           onSelect={(e,name)=>this.handleChangeEvent(e,name)}
                           disabled={formData.fields["is_multi_reviewer"]}
                        />
                </Col> */}
                {/* {!formData.fields["is_multi_reviewer"]&&<Col sm={12}>
                <InputField
                          minDate={new Date()}
                          type= "date"
                          placeholder="Select Date"
                          label="Due Date *"
                          error={formData.errors["due_date"]}
                          name= "due_date"
                          value={formData.fields["due_date"]}                           
                          onChange={(e)=>this.onDateChange(e,"due_date")}
                          
                       />
                </Col>} */}
                <Col sm={12}>
                    <div className="mr-3 ml-3 pb-1 border-bottom mb-3 d-flex align-items-center">
                    <span className="material-icons mr-2">
group_add
</span>
                        <h6 className='p-0 m-0'><strong>Add Reviewers</strong></h6></div>
                    
                    <div className='d-flex pl-2 pr-2'>
                    {/* <div  style={{flexBasis:"33.33%"}}>
                    <Form.Group  >
                        <Form.Check 
                        type="switch"
                        id="custom-switch"
                        disabled
                        label="Add Reviewers"
                        name="is_multi_reviewer"
                        style={{fontSize:".75rem"}}
                        defaultChecked={(formData.fields["is_multi_reviewer"])}
                        value={formData.fields["is_multi_reviewer"]}
                        onChange={(e)=>this.handleChangeEvent(e)}
                        />
                        
                        </Form.Group>
                        </div> */}
                        {formData.fields["is_multi_reviewer"]&&<>
                        <div  style={{flexBasis:"50%"}}>
                        <InputField
                        
                           type= "searchableDropdown"
                           placeholder="Please Select"
                           label="Reviewer or Coordinator *"
                           error={formData.errors["review_to_multi"]}
                           name= "review_to_multi"
                           value={formData.fields["review_to_multi"]}
                           options= {this.state.usersList}
                           onSelect={(e,name)=>this.handleChangeEventEx(e,name)}
                           
                        />
                        </div>
                         <div  style={{flexBasis:"50%"}}>
                         <InputField
                          
                           type= "searchableDropdown"
                           placeholder="Please Select"
                           label="Are you doing this form on behalf of a committee?"
                           error={formData.errors["is_committee"]}
                           name= "is_committee"
                           helptext=" YES:initiates a committee review form (e.g., Global Review Committee, GIRC, HERC, etc.)
                                    ,NO: initiates an individual reviewer form"
                           value={formData.fields["is_committee"]}
                           options= {this.state.committeeTypes}
                           onSelect={(e,name)=>this.handleChangeEventEx(e,name)}
                           disabled={!isReviewerSelected}
                        />
                        </div>
                        </>}
                        
                    </div>
                
                </Col>
                
                {formData.fields["is_multi_reviewer"]&&<Col sm={12}>
               <div className='d-flex pl-2 pr-2'>
                <div  style={{flexBasis:"33.33%"}}>
                <InputField
                          minDate={this.state.defaultMinDate}
                          type= "date"
                          placeholder="Please Select"
                          label={`Due Date${formData.fields["approval_order"] ==="Parallel"?"":"*"}`}
                          error={formData.errors["due_date"]}
                          name= "due_date"
                          value={formData.fields["due_date"]}                           
                          onChange={(e)=>this.onDateChange(e,"due_date")}
                          disabled={!isReviewerSelected}
                       />
                </div>
                <div  style={{flexBasis:"33.33%"}}>
                <InputField
                          
                           type= "searchableDropdown"
                           placeholder="Please Select"
                           label="Approval Order"
                           error={formData.errors["approval_order"]}
                           name= "approval_order"
                           value={formData.fields["approval_order"]}
                           options= {this.state.approvalOrderTypes}
                           onSelect={(e,name)=>this.handleChangeEventEx(e,name)}
                           disabled={!isReviewerSelected}
                        />
                </div>
                <div  style={{flexBasis:"33.33%"}}>
                         <InputField
                         
                           type= "searchableDropdown"
                           placeholder="Please Select"
                           label="Reviewer Type"
                           error={formData.errors["reviewer_type"]}
                           name= "reviewer_type"
                           value={formData.fields["reviewer_type"]}
                           options= {this.state.reviewerTypes}
                           onSelect={(e,name)=>this.handleChangeEventEx(e,name)}
                           disabled={!isReviewerSelected}
                        />
                        </div>
                        </div>
               </Col>}
               {formData.fields["is_multi_reviewer"]&&<Col sm={12}>
                    <MultiReviewerList 
                    source={this.state.multiReviewerSourceData} 
                    onChange={this.handleOnSelection}>                        
                    </MultiReviewerList>
                </Col>}
                <Col  sm={12}>
                <InputField
                          
                          type= "searchableDropdown"
                          placeholder="Please Select"
                          label="Additional Review Form"
                          error={formData.errors["addtnl_review_form"]}
                          name= "addtnl_review_form"
                          value={formData.fields["addtnl_review_form"]}
                          options= {this.state.additionalForms}
                          onSelect={(e,name)=>this.handleChangeEvent(e,name)}
                          disabled={!isReviewerSelected}
                       />
                </Col>
                <Col sm={12}>
                    <InputField
                           label= "Instruction for Selected Reviewers"
                           type= "textarea"
                           name= "instruction_to"
                           disabled={(!isReviewerSelected&&formData.fields["is_multi_reviewer"])}
                           value={formData.fields["instruction_to"]}
                           onChange={(e)=>this.handleChangeEvent(e)}
                           rows={3}
                        />
                </Col>
                <Col sm={12} md={12}>
                    {/* <div className="position-relative">
                    <InputField                          
                           type= "text"
                           placeholder="Sharepoint Link"
                           disabled={!isReviewerSelected}
                           readOnly={true}
                        />
                    </div> */}
                     <FileUploader placeholder="Sharepoint File" fileInfo={sharePointfileInfo} onChange={e=>{
                        let activeFile= e.target.files[0];                     
                        this.setState({sharePointfileInfo:{selectedFileName:activeFile.name,file:activeFile}},()=>{
                         if(this.state.formData.fields["is_multi_reviewer"]===true)
                         {
                             this.setFilesData(true);
                         }
                        
                        });
                    }}></FileUploader>
                   
                </Col>
                <Col sm={12} md={6}>
               
                    {/* <FileUploader fileInfo={fileInfo} onChange={e=>{
                        let activeFile= e.target.files[0];                     
                        this.setState({fileInfo:{selectedFileName:activeFile.name,file:activeFile}},()=>{
                         if(this.state.formData.fields["is_multi_reviewer"]===true)
                         {
                             this.setFilesData();
                         }
                        
                        });
                    }}></FileUploader> */}
                    {/* <div className="position-relative">
                    <InputField                          
                           type= "text"
                           readOnly={true}
                           placeholder="Upload File"
                           value={fileInfo.selectedFileName}
                        />
                         <span className="position-absolute" style={{fontSize:'2rem',right:'1rem',top:'-.5rem'}}>
                         <i onClick={e=>{
                           
                            if(this.filePicker.current){                            
                                this.filePicker.current.click();
                         }}} className="bi bi-cloud-arrow-up-fill"></i>
                        </span>
                        <input type="file" onChange={e=>{
                           let activeFile= e.target.files[0];
                           //console.log(activeFile);
                           this.setState({fileInfo:{selectedFileName:activeFile.name,file:activeFile}},()=>{
                            if(this.state.formData.fields["is_multi_reviewer"]===true)
                            {
                                this.setFilesData();
                            }
                           
                           });
                           
                        }} ref={this.filePicker} style={{width:0,position:'absolute',marginBottom:"-100px"}}></input>
                    </div> */}
                   
                </Col>
            </Row>
      </Modal.Body>
      <Modal.Footer>
                    {isIUDOperation&&<div className="d-flex align-items-center justify-content-center p-2">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>}
                    <div className="d-flex align-items-center justify-content-center w-100 p-2">
                        <ToolTipEx tooltip={DEFAULT.CANCEL}>
                            <Button {...loaderConfig} onClick={this.onHide}  style="danger w-100px" className='d-flex  align-items-center justify-content-center p-2 mr-2'>
                            <span>Cancel</span>
                            </Button>
                        </ToolTipEx>
                        <ToolTipEx tooltip={isEditMode?DEFAULT.EDIT:DEFAULT.ADD}>
                        <Button {...loaderConfig} onClick={this.handleSave} style="success w-100px" className='d-flex  align-items-center justify-content-center p-2 ml-2'>
                           {isEditMode && <div className="d-flex align-items-center">
                                <span className="material-icons mr-2">
                                    update
                                </span>
                               <span>UPDATE</span>
                            </div>
                          }
                           {!isEditMode && <>
                              <i className="bi bi-plus" ></i><span>ADD</span>
                            </>
                          }
                            </Button>
                            </ToolTipEx>
                           
                    </div>
                    
      </Modal.Footer>
    </Modal>
        </div>;
    }
}